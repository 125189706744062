import {  Grid, } from "@mui/material";
import React, { useContext, useEffect } from "react";
import {
  ContentGrid,
  Image,
  Paragraph,
  ParentGrid,
} from "./style";
import pic from "../../../assets/images/featureImage.png"
import { StateContext } from "../main";
const Features = (props) => {
  const { sectionVisibilty, setSectionVisibilty } = useContext(StateContext);
  useEffect(() => {
    const handleScroll = (event) => {
      if (event.deltaY > 0 && sectionVisibilty==="features") {
        // Scrolling down - hide the div
        setSectionVisibilty("tokenomics");
      } else if (event.deltaY < 0 && sectionVisibilty === "features") {
        // Scrolling up - show the div
        setSectionVisibilty("about");
      }
    };

    // Attach the scroll event listener to the window
    window.addEventListener("wheel", handleScroll);

    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener("wheel", handleScroll);
    };
  }, [sectionVisibilty]);
  return (
    <ParentGrid container {...props}>
      <ContentGrid item xs={12} md={6}>
        <Paragraph fs="18px" fs599="16px">Features</Paragraph>
        <Paragraph fs="32px" fs599="22px">Buy back and burn</Paragraph>
        <Paragraph>
          As part of our commitment to the community, we've implemented a robust
          buyback and burn feature. This means that a portion of our tokens will
          be regularly repurchased from the market and burnt out forever,
          reducing the overall supply and increasing the value of each token.
          It's a tangible way for us to give back to our loyal holders, ensuring
          that the Ape project continues to evolve and thrive. Join us on this
          exciting adventure, where your investment isn't just a token, but a
          ticket to the future of decentralized finance.
        </Paragraph>
      </ContentGrid>
      <Grid item xs={12} md={5} sx={{display:"flex",justifyContent:"center"}}>
        <Image src={pic}/>
      </Grid>
    </ParentGrid>
  );
};

export default Features;
