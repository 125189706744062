import React, { useContext, useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import logo from "../../../../assets/images/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import {
  MenuLink,
  MobileMainMenu,
  SiteLogo,
  DrawerBox,
  DrawerDivider,
  MenuButton,
} from "../styles";
import { StateContext } from "../../../Pages/main";

const MobileMenu = (props) => {
  const {  setSectionVisibilty } = useContext(StateContext);

  const [state, setState] = useState({ left: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  return (
    <MobileMainMenu>
      <MenuLink  onClick={()=>setSectionVisibilty("main")} p="15px 0 15px">
        <SiteLogo src={logo} style={{ width: "100px" }} alt="Logo" />
      </MenuLink>
      <MenuButton onClick={toggleDrawer("left", true)}>
        <MenuIcon />
      </MenuButton>
      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        <DrawerBox
          role="presentation"
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
        >
          <DrawerDivider>
            <MenuLink p="15px 0"  onClick={()=>setSectionVisibilty("main")}>
              <SiteLogo src={logo} style={{ width: "120px" }} alt="Logo" />
            </MenuLink>
            <Divider />
            <List>
            <MenuLink
              onClick={()=>setSectionVisibilty("about")}
            >
              ABOUT
            </MenuLink>
            <MenuLink
              onClick={()=>setSectionVisibilty("features")}
            >
              FEATURES
            </MenuLink>
            <MenuLink
              onClick={()=>setSectionVisibilty("tokenomics")}
            >
              TOKENOMICS
            </MenuLink>
            <MenuLink
            >
              BUY/SELL
            </MenuLink>
            </List>
          </DrawerDivider>
          <DrawerDivider>
            <List></List>
          </DrawerDivider>
        </DrawerBox>
      </Drawer>
    </MobileMainMenu>
  );
};

export default MobileMenu;
