import React, { useContext, useEffect } from "react";
import {
  ContentGrid,
  Paragraph,
  ParentGrid,
} from "./style";
import { StateContext } from "../main";
const Tokenomics = (props) => {
  const { sectionVisibilty, setSectionVisibilty } = useContext(StateContext);
  useEffect(() => {
    const handleScroll = (event) => {
      if (event.deltaY > 0 && sectionVisibilty === "tokenomics") {
        // Scrolling down - hide the div
        // setSectionVisibilty("features");
      } else if (event.deltaY < 0 && sectionVisibilty === "tokenomics") {
        // Scrolling up - show the div
        setSectionVisibilty("features");
      }
    };

    // Attach the scroll event listener to the window
    window.addEventListener("wheel", handleScroll);

    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener("wheel", handleScroll);
    };
  }, [sectionVisibilty]);
  return (
    <ParentGrid container {...props}>
      <ContentGrid item xs={12} md={7}>
        <Paragraph fs="18px" fs599="14px">
        Tokenomics
        </Paragraph>
        <Paragraph fs="32px" fs599="22px">
        Token detail
        </Paragraph>
        <Paragraph>
          Ticker: $Apereum <br />
          Total Supply: TBA
          <br />
          Taxes: TBA <br />
          Slippage: TBA
          <br />
          <br />
          contract: 0xxxx00000xxxxxxxxxx
        </Paragraph>
      </ContentGrid>
    </ParentGrid>
  );
};

export default Tokenomics;
