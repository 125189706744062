import styled from "@emotion/styled";
import { Container, Grid, Typography } from "@mui/material";

const SectionWraper = styled(Container)`
  padding: 0 !important;
  width: 100%;
  height: 100vh;
  z-index: 0;
  position: relative;
  background-image: url("./Image/roadmapBg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 599px) {
    height: "1";
  }
`;
const GradiantDiv = styled.div`
  padding: 0 !important;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left:0;
  z-index: 1;
  background-image: radial-gradient(circle at 110% 50%, #0000, #091C23 60%);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;
const ContainerWraper = styled(Container)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
`;
const EmptyGrid = styled(Grid)`
  width: 100%;
  height: 80px;
  @media (max-width: 599px) {
    display: none;
  }
`;
const Paragraph = styled(Typography)`
  color: #fff;
  font-size: ${props => props.fs? props.fs :"14px"};
  font-family: Lucida;
  z-index: 1;
  text-transform: uppercase;
  @media(max-width: 599px){
    text-align: left;
    font-size: ${props => props.fs599? props.fs599 :"12px"};
  }
`;
const Image = styled.img`
  width: 100%;
  max-width: 50%;
  height: auto;
  z-index: 1;
  @media (max-width: 599px) {
    display: none;
  }
`;
const RoadMapImage = styled.img`
    width: 100%;
    max-width: 40px;
`;
const RoadMapImageDiv = styled.div`
  position: absolute;
    display: flex;
    width: 55px;
    padding: 10px;
    border: 1px solid #F88523;
    border-radius: 40px;
    background: #1F3037;
    top: -10%;
    left: 42%;
    justify-content: center;
    align-items: center;
`;
const ImageGrid = styled(Grid)`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 599px) {
    justify-content: center;
  }
`;
const ParentGrid = styled(Grid)`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  @media (max-width: 599px) {
    flex-direction: column-reverse;
  }
`;
const ContentGrid = styled(Grid)`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* margin: 20px 0 0; */
  @media (max-width: 599px) {
    margin: 10px 0 0;
  }
`;
const RoadMapDiv = styled.div`
    width: 300px;
    height: 270px !important;
    padding: 30px 0;
    margin: 0 5px;
    height: auto;
    background: #1F3037;
    border: 1px solid #F88523;
    border-radius: 20px;
    position: relative;
    margin-top: 60px;
`;
const FooterDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
  @media(max-width :599px){
        flex-direction: column;
    }
`;

const SocialDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: PoppinsR;
  margin: 10px 0 0;
  z-index: 1;
  /* @media(max-width :599px){
        flex-direction: column;
    } */
`;

export {
  SectionWraper,
  Paragraph,
  EmptyGrid,
  Image,
  ImageGrid,
  ParentGrid,
  ContentGrid,
  RoadMapDiv,
  FooterDiv,
  SocialDiv,
  GradiantDiv,
  ContainerWraper,
  RoadMapImage,
  RoadMapImageDiv
};
