import styled from "@emotion/styled";
import React, { createContext, useEffect, useState } from "react";
import bg from "../../../assets/images/bgapereum.mp4";
import { Container, Grid, Link, Typography } from "@mui/material";
import CustomButton from "../../Components/CustomBtn";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import LockerMenu from "../../Components/Menu";
import About from "../S2-About";
import Features from "../S3-Features";
import Tokenomics from "../P2-Tokenomics";
const VIDEO = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
`;
const SectionWraper = styled(Container)`
  padding: 0 !important;
  width: 100%;
  min-height: 100vh;
  z-index: 0;
  position: relative;
  background: ${(props) =>
    props.state === "main" ? "transparent" : "#0000009b"};
  backdrop-filter: ${(props) =>
    props.state === "main" ? "none" : "blur(20px)"};
    
`;

const ContainerWraper = styled(Container)`
  width: 100%;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.state === "main" ? "end" : "space-between"};
  align-items: center;
`;
const GridWraper = styled(Grid)`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  @media(max-width: 599px){
    flex-direction: column;
  }
`;
const Paragraph = styled(Typography)`
  color: #fff;
  font-size: 16px;
  font-family: Lucida;
  margin-top: 20px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  text-transform: uppercase;
  @media(max-width: 599px){
    text-align: center;
  }
`;
export const StateContext = createContext();
const MainPage = () => {
  const [sectionVisibilty, setSectionVisibilty] = useState("main");
  useEffect(() => {
    const handleScroll = (event) => {
      if (event.deltaY > 100 && sectionVisibilty) {
        // Scrolling down - hide the div
        // setSectionVisibilty("about");
      } else if (event.deltaY < 2 && sectionVisibilty) {
        // Scrolling up - show the div
        setSectionVisibilty("main");
      }
    };

    // Attach the scroll event listener to the window
    window.addEventListener("wheel", handleScroll);

    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener("wheel", handleScroll);
    };
  }, [sectionVisibilty]);
  return (
    <>
      <StateContext.Provider value={{ sectionVisibilty, setSectionVisibilty }}>
      <div
        style={{
          width: "100%",
          maxHeight: "100vh",
        }}
      >
        <VIDEO autoPlay muted loop>
          <source src={bg} type="video/mp4"></source>
        </VIDEO>
      </div>
        <SectionWraper maxWidth="xxl" state={sectionVisibilty}>
          <LockerMenu />
          <ContainerWraper state={sectionVisibilty}>
            {sectionVisibilty === "about" ? (
              <About />
            ) : sectionVisibilty === "features" ? (
              <Features />
            ) : sectionVisibilty === "tokenomics" ? (
                <Tokenomics />
              ) : (
              <Grid container>
                <GridWraper item xs={12}>
                  <CustomButton onClick={() => setSectionVisibilty("about")}>
                    Explore more
                  </CustomButton>
                </GridWraper>
              </Grid>
            )}
            <Grid container>
              <GridWraper item xs={12} jc="space-between">
                <Paragraph>Smart contract: 0xxxx00000xxxxxxxxxx</Paragraph>
                <Paragraph>
                  Follow us:
                  <Link
                    href="https://twitter.com/Apereum_Erc20"
                    target="blank"
                    style={{
                      color: "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <TwitterIcon />
                    <span style={{ textDecoration: "underline" }}>Twitter</span>
                  </Link>
                  <Link
                    href="https://t.me/Apereum_Erc20"
                    target="blank"
                    style={{
                      color: "#fff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <TelegramIcon />
                    <span style={{ textDecoration: "underline" }}>
                      Telegram
                    </span>
                  </Link>
                </Paragraph>
              </GridWraper>
            </Grid>
          </ContainerWraper>
        </SectionWraper>
      </StateContext.Provider>
    </>
  );
};

export default MainPage;
