import React, { useContext, useEffect } from "react";
import {
  ContentGrid,
  Paragraph,
  ParentGrid,
} from "./style";
import { StateContext } from "../main";
const About = (props) => {
  const {sectionVisibilty, setSectionVisibilty} = useContext(StateContext)
  useEffect(() => {
    const handleScroll = (event) => {
      if (event.deltaY > 0 && sectionVisibilty==="about") {
        // Scrolling down - hide the div
        setSectionVisibilty("features");
      } else if (event.deltaY < 0 && sectionVisibilty==="about") {
        // Scrolling up - show the div
        setSectionVisibilty("main");
      }
    };

    // Attach the scroll event listener to the window
    window.addEventListener('wheel', handleScroll);

    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener('wheel', handleScroll);
    };
  }, [sectionVisibilty]);
  return (
      <ParentGrid container {...props}>
        <ContentGrid item xs={12} md={7}>
          <Paragraph fs="18px" fs599="14px">ABOUT</Paragraph>
          <Paragraph fs="32px" fs599="22px">Join the $Apereum MOVEMENT</Paragraph>
          <Paragraph>
            Step into the Apereum movement, where we embark on a captivating
            journey within the dynamic realm of the contemporary crypto
            landscape. Our mission is simple: infuse innovation and enjoyment
            into the heart of the community. We're assembling a diverse and
            thriving group of individuals who share our profound appreciation
            for decentralized meme coins. As a collective force, we aim to craft
            an extraordinary and exhilarating odyssey within the cryptocurrency
            sphere—one where principles of transparency, longevity, and
            creativity flourish and unite us all. Join us, and together, let's
            redefine the possibilities of decentralized finance.
          </Paragraph>
        </ContentGrid>
      </ParentGrid>
  );
};

export default About;
