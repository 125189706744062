import { ThemeProvider, createTheme } from "@mui/material";
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import MainPage from "../Views/Pages/main";
const getActiveTheme = (themeMode) => {
  return createTheme({
    pageColor: '#000',

    bgPrimary: '#161616',
    bgSecondary: 'red',

    textPrimary: '#ffffff',
    textSecondary: 'black',
    textTertiary: '#0E0D0D',
    BtnHover: '#311f1f7b',

    switchColor: '#b1b1b1',
  })
}
const ThemeContainer = () => {
  const currentTheme = useSelector((state) => state.LDTheme.value);
  const [activeTheme, setActiveTheme] = useState(getActiveTheme(currentTheme));

  useEffect(()=>{    
    setActiveTheme(getActiveTheme(currentTheme))
  },[currentTheme]);
  return (
    <ThemeProvider theme={activeTheme}>
      <MainPage toggleTheme='' />
    </ThemeProvider>
  );
}

export default ThemeContainer;