import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@mui/material'

const CustomBtn = styled(Button)`
    background-color: ${props => props.bgc ? props.bgc : "#024BDA"};
    font-size: 14px;
    font-family: Lucida;
    margin:  0 5px;
    color:#fff;
    transition: .5s;
    cursor: pointer;
    z-index: 1;
    :hover{
      background-color: #024BDA;
      color: ${props => props.bgc ? props.bgc : "#fff"};
    }

`

const CustomButton = (props) => {
  const {children} = props
  return (
    <CustomBtn variant='filled' {...props}>
        {children}
    </CustomBtn>
  )
}

export default CustomButton