import React from "react";
import DesktopMenu from "./component/DesktopMenu";
import MobileMenu from "./component/MobileMenu";


import { Container } from "@mui/material";

const LockerMenu = (props) => {

  
  return (
    <>
    <Container>
      <DesktopMenu  />
      <MobileMenu />
    </Container>
    </>
  );
};

export default LockerMenu;
