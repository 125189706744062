import React, { useContext } from "react";
import { DesktopMainMenu, MenuLink, Containers } from "../styles";
import logo from "../../../../assets/images/logo.png";
import { StateContext } from "../../../Pages/main";
const DesktopMenu = (props) => {
  const { sectionVisibilty, setSectionVisibilty } = useContext(StateContext);
  return (
    <DesktopMainMenu>
      <Containers maxWidth="xl">
        <MenuLink  onClick={()=>setSectionVisibilty("main")} p="0px">
          <img src={logo} alt="Logo" style={{ width: "120px" }} />
        </MenuLink>
        <div style={{ display: "flex", alignItems: "center" }}>
            <MenuLink
              onClick={()=>setSectionVisibilty("about")}
            >
              ABOUT
            </MenuLink>
            <MenuLink
              onClick={()=>setSectionVisibilty("features")}
            >
              FEATURES
            </MenuLink>
            <MenuLink
              onClick={()=>setSectionVisibilty("tokenomics")}
            >
              TOKENOMICS
            </MenuLink>
            <MenuLink
            >
              BUY/SELL
            </MenuLink>

        </div>
      </Containers>
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
